const itemsReducer = (state, action) => {
  switch (action.type) {
    case 'POPULATE_ITEMS':
      return action.items;
    case 'ADD_ITEM':
      if (state.find(obj => obj.name === action.name)) {
        return state;
      }
      else {
        return state.concat({ name: action.name });
      }
    case 'REMOVE_ITEM':
      return state.filter((item) => item.name !== action.name);
    default:
      return state;
  }
};
export { itemsReducer as default };
