import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.svg'
import ItemCount from '../components/ClassLists/ItemCount'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="navbar-brand py-5 pl-5">
          <Link to="/" className="navbar-item logo" title="Logo">
            <img src={logo} alt="Babes Helpin' Babes" />
          </Link>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
            data-target="navMenu"
            onClick={() => this.toggleHamburger()}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${this.state.navBarActiveClass}`}
        >
          <div className="navbar-end has-text-centered px-5 py-3">
            <Link className="navbar-item" to="/about">
              ABOUT
              </Link>
            <Link className="navbar-item" to="/our-story">
              OUR STORY
              </Link>
            <Link className="navbar-item" to="/get-started">
              GET STARTED
              </Link>
            <Link className="navbar-item" to="/chapters">
              CHAPTERS
              </Link>
            <Link className="navbar-item" to="/resources">
              RESOURCES
              </Link>
            <Link className="navbar-item pr-2" to="/contact">
              CONTACT<ItemCount />
            </Link>

          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
