import React, { useContext } from 'react'
import { Link } from 'gatsby'
import ItemsContext from '../../components/context/items-context'

const ItemCount = () => {
  const { items } = useContext(ItemsContext);
  return (
    <>
      {
        items.length >= 1 ?
          <>
            <span className="item-count ml-2">{items.length}</span>
          </>
          : null
      }
    </>
  );
};

export { ItemCount as default };
