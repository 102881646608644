import React from 'react';

const ItemsContext = React.createContext({
  items: [],
  itemsDispatch: () => { },
});

export const ItemsConsumer = ItemsContext.Consumer

//export { ItemsContext as default };
export default ItemsContext