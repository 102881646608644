import React from 'react'
//import { Link } from 'gatsby'

import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content">
          <div className="container">
            <div className="has-text-centered">
              <a title="facebook" href="https://www.facebook.com/Babes-Helpin-Babes-100394325131980" target="_blank" rel="noreferrer noopener" className="px-2">
                <img
                  src={facebook}
                  alt="Facebook"
                  style={{ width: '1.5em', height: '1.5em' }}
                />
              </a>
              <a title="instagram" href="https://www.instagram.com/babeshelpinbabes/" target="_blank" rel="noreferrer noopener" className="px-1">
                <img
                  src={instagram}
                  alt="Instagram"
                  style={{ width: '1.5em', height: '1.5em' }}
                />
              </a>
            </div>
            <div className="columns is-desktop my-5 smaller is-centered" style={{ maxWidth: '910px', margin: '0 auto' }}>
              <div className="column is-one-third-desktop has-text-centered">KANSAS CITY, MO</div>
              <div className="column is-one-third-desktop has-text-centered">©{(new Date().getFullYear())} BABES HELPIN' BABES</div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
